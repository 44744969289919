<template>
  <div class='plk_import'>
    <Menu></Menu>
    <div class='import'>
      <h5>{{$t('designer.upload.titlepage')}}</h5>
      <Uploader simpleFile acceptType="image/png,image/gif,image/svg+xml,image/jpeg,text/html,application/json,text/css,text/javascript" @loadFile="uploadFile"></Uploader>
      <!-- Vista pantalla -->
      <div class="row">
        <div class="two ml-3 mb-3 item" :title="$t('designer.upload.listfiles.createemptyfile')">
          <div class="filecontainer pointer" @click="createFile()">
            <div class="icon">
              <i class="fas fa-file-medical"></i>
            </div>
            <div class="middle">
              <div class="text">
                {{$t('designer.upload.listfiles.createemptyfile')}}
              </div>
            </div>
          </div>
        </div>
        <div class="two ml-3 mb-3 item" :class="{ 'editing': editImage === file }" v-for="(file, index) in files" :key="index" :title="file">
          <i v-if="editImage !== file" @click.stop="editImage = file" class="pointer edit fas fa-edit p-2 fa-edit" :title="$t('designer.upload.listfiles.edit')"></i>
          <i v-if="editImage === file" @click.stop="$refs[`submitRename${index}`][0].click()" class="pointer edit fas fa-edit p-2 fa-save" :title="$t('designer.upload.listfiles.save')"></i>
          <i @click.stop="copyFile(file)" class="pointer copy fas fa-copy p-2 mt-5" :title="$t('designer.upload.listfiles.edit')"></i>
          <i @click.stop="deleteImage(file)" class="pointer remove fas fa-trash-alt p-2 mt-10" :title="$t('designer.upload.listfiles.delete')"></i>
          <div class="filecontainer pointer" @click="openFile(file)">
            <img v-if="getContent(file).isImage" :src="getContent(file).data" class="pointer file">
            <div class="icon" v-if="getContent(file).isUndefinedFile">
              <i class="fas fa-file-archive"></i>
            </div>
            <div class="icon" v-else>
              <i :class="{
                'fab fa-js-square': getContent(file).isJavascript,
                'fab fa-css3-alt': getContent(file).isCSS,
                'fas fa-file-code': getContent(file).isJSON || getContent(file).isPython,
                'fab fa-html5': getContent(file).isHtml,
                'fas fa-file-invoice': getContent(file).isTextPlain
              }"></i>
            </div>
            <div class="middle">
              <div class="text" v-if="editImage !== file">
                {{file}}
              </div>
              <form class="mb-0 pb-0" @submit.prevent="editImage = undefined; renameFile(file, renameFileParam[file])" v-else>
                <input @click.stop="" required pattern="[\/\\A-ZÑa-zñ_\-\.1-9]+\.[A-Za-z]{2,5}" class="u-full-width mb-0 pb-0" type="text" v-model="renameFileParam[file]"/>
                <button :ref="`submitRename${index}`" type="submit" class="d-none"></button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Store, DESIGN } from '@/store'
import i18n from './i18n'
import { v4 as uuidv4 } from 'uuid'
import Uploader, { isImage, isHTML, isJavascript, isJSON, isCSS, isTextPlain, isPython, isUndefinedFile } from './components/uploaders/Uploader'
import Menu from './components/menus/Menu'
import { VIEWS } from '@/router/design'

export default {
  name: 'files',
  components: {
    Menu,
    Uploader
  },
  data () {
    return {
      fileName: '',
      isLoading: false,
      editImage: undefined,
      renameFileParam: {}
    }
  },
  computed: {
    files () {
      return Store.getters[DESIGN.GETTERS.GET_ALL_FILES]()
    },
    views () {
      return Store.getters[DESIGN.GETTERS.GET_VIEWS]()
    }
  },
  methods: {
    onInitComponent () {
      this.$servicesPLK.translation.init(i18n, { overwrite: false })
      this.loadFileToRename()
    },
    openFile (file) {
      if (this.getContent(file).isImage) {
        this.openNewTabImage(file)
      } else if (!this.getContent(file).isUndefinedFile) {
        this.editFile(file)
      }
    },
    loadFileToRename () {
      Vue.set(this, 'renameFileParam', {})
      this.files.forEach((file) => { Vue.set(this.renameFileParam, file, file) })
    },
    uploadFile ({ filename, content }) {
      if (filename && content) {
        Store.commit({
          type: DESIGN.MUTATIONS.CREATE_FILE,
          filename,
          content
        })
      }
    },
    deleteImage (filename) {
      if (filename) {
        this.$modal.show('dialog', {
          title: this.$t('designer.upload.listfiles.deletefile.title'),
          text: this.$t('designer.upload.listfiles.deletefile.text', { name: filename }),
          buttons: [
            {
              title: this.$t('designer.upload.listfiles.deletefile.modal.cancel'),
              handler: () => {
                this.$modal.hide('dialog')
              }
            },
            {
              title: this.$t('designer.upload.listfiles.deletefile.modal.confirm'),
              handler: () => {
                Store.commit({
                  type: DESIGN.MUTATIONS.DELETE_FILE,
                  filename
                })
                this.$modal.hide('dialog')
              }
            }
          ]
        })
      }
    },
    renameFile (oldPath, newPath) {
      if (oldPath && newPath) {
        Store.commit({
          type: DESIGN.MUTATIONS.RENAME_FILE,
          oldPath,
          newPath
        })
      }
    },
    copyFile (filename) {
      if (filename) {
        const content = this.getContent(filename)
        this.uploadFile({ filename: `${uuidv4()}_${filename}`, content })
      }
    },
    getContent (filename) {
      return Store.getters[DESIGN.GETTERS.GET_FILE_CONTENT](filename)
    },
    openNewTabImage (filename) {
      let image = new Image()
      image.src = this.getContent(filename).data
      let w = window.open('', '_blank')
      w.document.write(image.outerHTML)
    },
    editFile (file) {
      this.$router.push({
        name: VIEWS.FILE_EDITOR.name,
        query: { file }
      })
    },
    createFile () {
      this.$modal.show(
        {
          template: `
            <div class="dialog-create">
              <form @submit.prevent="confirm" class="m-0">
                <div class="dialog-content">
                  <div class="vue-dialog-content-title">{{title}}</div>
                  <div>{{text}}</div>
                  <input pattern="[\\/\\\\A-ZÑa-zñ_\\-\\.1-9]+\\.[A-Za-z]{2,5}" v-model="filename" required class="u-full-width mt-3" type="text" placeholder="file.txt" id="fileName">
                </div>
                <div class="vue-dialog-buttons">
                  <button type="button" @click="cancel" class="vue-dialog-button" style="flex: 1 1 50%;">
                    {{$t('designer.upload.listfiles.createfile.modal.cancel')}}
                  </button>
                  <button type="submit" class="vue-dialog-button" style="flex: 1 1 50%;">
                    {{$t('designer.upload.listfiles.createfile.modal.confirm')}}
                  </button>
                </div>
              </form>
            </div>
          `,
          props: ['name', 'title', 'text', 'onConfirm'],
          data () {
            return {
              filename: ''
            }
          },
          methods: {
            confirm () {
              if (this.onConfirm) this.onConfirm(this.filename)
            },
            cancel () {
              this.$emit('close')
            }
          }
        },
        {
          text: this.$t('designer.upload.listfiles.createfile.text'),
          title: this.$t('designer.upload.listfiles.createfile.title'),
          name: 'createFile',
          onConfirm: (filename) => {
            Store.commit({
              type: DESIGN.MUTATIONS.CREATE_FILE,
              filename,
              content: {
                data: '',
                base64Data: '',
                isImage: isImage({ filename }),
                isHtml: isHTML({ filename }),
                isJavascript: isJavascript({ filename }),
                isTextPlain: isTextPlain({ filename }),
                isJSON: isJSON({ filename }),
                isPython: isPython({ filename }),
                isCSS: isCSS({ filename }),
                isUndefinedFile: isUndefinedFile({ filename })
              }
            })
            this.$modal.hideAll()
          }
        },
        { height: 'auto', draggable: true, adaptive: true, class: 'vue-dialog' }
      )
    }
  },
  watch: {
    files () {
      this.loadFileToRename()
    }
  }
}
</script>
<style lang="scss">
  .dialog-create {
    .dialog-content {
      padding: 2rem;
    }
  }
</style>
<style scoped lang='scss'>
  .plk_import {
    .mt-10 {
      margin-top: 6rem !important;
    }
    .import {
      padding: 3vh;
      padding-left: calc(3vh + 50px);

      .item {
        height: 140px;
        .file {
          opacity: 1;
          display: block;
          margin: auto;
          max-height: 100%;
          transition: .5s ease;
          backface-visibility: hidden;
        }
        .middle {
          transition: .5s ease;
          opacity: 0;
          width: 100%;
          text-align: left;
          .text {
            overflow-y: hidden;
            overflow-x: auto;
            white-space: nowrap;
            height: 40px;
            text-align: center;
          }
        }
        .filecontainer {
          height: 30vh;
          .icon {
            text-align: center;
            font-size: 80px;
          }
          &:hover {
            .file {
              opacity: 0.3;
            }
            .middle {
              opacity: 1;
            }
          }
        }
        img {
          border-radius: 5px;
          max-width: 100%;
        }
        .copy,
        .remove,
        .edit {
          position: absolute;
          z-index: 99;
          border-radius: 0% 25% 25% 0%;
          background: white;
          display: none;
          color: #bb1414;
        }
        .edit {
          color: green;
        }
        .copy {
          color: cadetblue;
        }
      }
      .item.editing {
        .file {
          opacity: 0.3;
        }
        .middle {
          opacity: 1;
        }
      }
      .item.editing .copy,
      .item.editing .remove,
      .item.editing .edit,
      .item:hover .copy,
      .item:hover .remove,
      .item:hover .edit{
        display: inline;
      }
    }

    @media (max-width: breakpoint-max('sm', $grid-breakpoints))  {
      .row {
        .two {
          width: 90%;
        }
        display: flex;
        flex-direction: column;
        .item {
          height: 50px;
          .filecontainer {
            display: flex;
            min-height: 60px;
            height: 10vh;
            .icon {
              font-size: 35px;
            }
            .middle {
              opacity: 100;
              .text {
                text-align: left;
                padding: 1.5rem;
              }
            }
          }
        }
      }
    }
  }
</style>
